<template>
  <div
    class="fixed z-underDrawer box-content flex w-full items-center bg-primary text-white shadow-xl transition-all"
    data-el="ui-mobile-bar"
    data-test="ui-mobile-bar"
    :style="{ bottom: 'env(safe-area-inset-bottom, 0)' }"
  >
    <div class="flex size-full items-center px-3">
      <NuxtLink
        v-for="(item) in items"
        :key="item.id"
        no-prefetch
        class="flex h-full flex-1 flex-col items-center justify-center text-inherit"
        :aria-label="item.aria"
        :to="item.link"
        :data-test="`ui-mobile-bar-link-${item.id}`"
        @click.prevent="() => item.callback?.()"
      >
        <div class="relative">
          <div class="flex size-6 items-center justify-center">
            <UiIcon
              :name="item.icon"
              :width="item.width"
              :height="item.height"
            />
          </div>

          <div
            v-if="item.icon === 'cart' && itemsInCart"
            class="absolute -bottom-1 left-3.5 flex h-4 items-center justify-center rounded-full border-2 border-blue px-1 text-xxs font-bold text-white"
            :style="{ backgroundColor: '#00BAFF' }"
          >
            {{ itemsInCart }}
          </div>
        </div>
        <p class="mt-auto text-xxs">
          {{ item.title }}
        </p>
        <span
          v-if="item.aria"
          class="sr-only"
        >{{ item.aria }}</span>
      </NuxtLink>

      <button
        class="-mt-2 size-16 rounded-full bg-primary p-1"
        data-test="ui-mobile-bar-link-search"
        @click.prevent="emit('search-click')"
      >
        <span
          class="flex size-full flex-col items-center justify-center rounded-full bg-blue-50 text-inherit"
        >
          <UiIcon
            name="search"
            height="20"
          />
          <p class="mt-0.5 text-xxs">{{ labels.search }}</p>
        </span>
      </button>

      <NuxtLink
        v-if="productsItem"
        :to="productsItem.link"
        data-test="ui-mobile-bar-link-products"
        class="flex h-full flex-1 flex-col items-center justify-center text-inherit"
      >
        <div class="flex size-6 items-center justify-center">
          <UiIcon
            :name="productsItem.icon"
            :height="productsItem.height"
            :width="productsItem.width"
          />
        </div>
        <p class="mt-0.5 text-xxs">
          {{ productsItem.title }}
        </p>
      </NuxtLink>
      <button
        class="flex h-full flex-1 flex-col items-center justify-center text-inherit"
        data-test="ui-mobile-bar-link-menu"
        @click.prevent="emit('hamburger-click')"
      >
        <div class="flex size-6 items-center justify-center">
          <!-- eslint-disable-next-line tailwindcss/no-custom-classname -->
          <span class="hamburger flex h-5 flex-col justify-between p-0.5">
            <span
              v-for="item in 3"
              :key="item"
              class="block h-[3px] w-6 bg-white"
            />
          </span>
        </div>
        <p class="mt-0.5 text-xxs">
          Menu
        </p>
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import UiIcon from '../UiIcon/UiIcon.vue'

type UiMobileBarLink = {
  id: 'dashboard' | 'login' | 'cart' | 'products'
  title: string
  aria?: string
  icon: string
  width: number
  height: number
  link?: string
  callback?: () => void
} & ({ link: string } | { callback: () => void })

interface UiMobileBar {
  links?: Array<UiMobileBarLink>
  labels: {
    search?: string
  }
  itemsInCart?: number | undefined
}

const props = defineProps<UiMobileBar>()

const emit = defineEmits<{
  (e: 'hamburger-click'): void
  (e: 'search-click'): void
}>()

const items = computed(() => props.links?.filter(item => item.id !== 'products') ?? [])
const productsItem = computed(() => props.links?.find(item => item.id === 'products') ?? [])
</script>
